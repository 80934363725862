<template>
  <div class="bg-gradient-to-r from-gray-900 via-gray-800 to-gray-900">
    <div class="mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl">
      <nav
        class="flex bg-opacity-0 border-opacity-25 p-1 border-b border-zinc-600"
      >
        <div class="flex-none">
          <div class="inline-block flex">
            <a
              href="/"
              class="text-lg px-2 py-1 sm:px-4 sm:py-2 border-none rounded text-white cursor-pointer mr-2"
            >
              <span class="leading-tight whitespace-nowrap text-shadow-white">
                <img
                  class="h-8 inline"
                  src="https://media.discordapp.net/attachments/1080058777971212370/1083317410674769940/PromptDue_Logo_64x64px_02.png?width=128&height=128"
                />
                <b class="font-sans font-semibold"> Promptdue </b>
              </span>
            </a>

            <div class="mt-1">
              <router-link to="/search" class="inline-block lg:hidden sm:block">
                <div
                  class="text-sm px-2 py-1 sm:px-4 sm:py-2 border-none rounded text-white bg-gray-800 cursor-pointer mr-2"
                >
                  <i class="fa fa-search" style="font-size: 20px"></i>
                </div>
              </router-link>
            </div>

            <div class="mt-1">
              <div
                class="inline-block lg:hidden sm:block"
                v-on:click="menuMobile()"
              >
                <div
                  class="text-sm px-2 py-1 sm:px-4 sm:py-2 border-none rounded text-white bg-gray-800 cursor-pointer mr-2"
                >
                  <i class="fas fa-align-justify" style="font-size: 20px"></i>
                </div>
              </div>
            </div>

            <!-- <div class="mt-1">
            <router-link to="/search" class="inline-block hidden lg:block">
              <div
                class="text-sm px-2 py-1 sm:px-4 sm:py-2 border-none rounded text-white bg-blue-800 cursor-pointer mr-2"
              >
                <i class="fas fa-store-alt-slash mr-1"> </i>

                <span> Marketplace </span>
              </div>
            </router-link>
          </div> -->

            <!-- <div class="mt-1">
              <router-link to="/funds" class="inline-block hidden lg:block">
                <div
                  class="text-sm px-2 py-1 sm:px-4 sm:py-2 border-none rounded text-white cursor-pointer mr-2 hover:text-blue-300"
                >
                  <i class="fas fa-user-circle mr-1"></i>

                  <span> Account</span>
                </div>
              </router-link>
            </div> -->

            <!-- <div class="mt-1">
              <router-link to="/sell" class="inline-block hidden lg:block">
                <div
                  class="text-sm px-2 py-1 sm:px-4 sm:py-2 border-none rounded text-white cursor-pointer mr-2 hover:text-blue-300"
                >
                  <span> Sell prompt </span>

                  <i class="fas fa-arrow-right ml-1"></i>
                </div>
              </router-link>
            </div> -->

            <!-- <div class="mt-1">
              <router-link
                to="/search/Free"
                class="inline-block hidden lg:block"
              >
                <div
                  class="text-sm px-2 py-1 sm:px-4 sm:py-2 border-none rounded text-white cursor-pointer mr-2 hover:text-blue-300"
                >
                  <span> Free Prompt </span>

                  <i class="fas fa-arrow-right ml-1"></i>
                </div>
              </router-link>
            </div> -->

            <!-- 
            <div class="mt-1">
              <router-link to="/search" class="inline-block hidden lg:block">
                <div
                  class="text-sm px-2 py-1 sm:px-4 sm:py-2 border-none rounded text-white cursor-pointer mr-2 hover:text-blue-300"
                >
                  <span> Search Prompt </span>

                  <i class="fa fa-search ml-1"></i>
                </div>
              </router-link>
            </div>

            <div class="mt-1">
              <a href="/models" class="inline-block hidden lg:block">
                <div
                  class="text-sm px-2 py-1 sm:px-4 sm:py-2 border-none rounded text-white cursor-pointer mr-2 hover:text-blue-300"
                >
                  <span> Models </span>
                </div>
              </a>
            </div>

            <div class="mt-1">
              <a
                href="https://promptdue.gitbook.io/promptdue/"
                class="inline-block hidden lg:block"
                target="_blank"
              >
                <div
                  class="text-sm px-2 py-1 sm:px-4 sm:py-2 border-none rounded text-white cursor-pointer mr-2 hover:text-blue-300"
                >
                  <span> Docs </span>
                </div>
              </a>
            </div> -->

            <!-- <div class="mt-1">
              <a href="/genAI" class="inline-block hidden lg:block">
                <div
                  class="text-sm px-2 py-1 sm:px-4 sm:py-2 border-none rounded text-white cursor-pointer mr-2 hover:text-blue-300"
                >
                  <span> Text to Images </span>

                  <i class="fas fa-pencil-alt ml-1"></i>
                </div>
              </a>
            </div>

            <div class="mt-1">
              <a href="/genImagetoImage" class="inline-block hidden lg:block">
                <div
                  class="text-sm px-2 py-1 sm:px-4 sm:py-2 border-none rounded text-white cursor-pointer mr-2 hover:text-blue-300"
                >
                  <span> Image to Image </span>

                  <i class="fas fa-pencil-alt ml-1"></i>
                </div>
              </a>
            </div>

            <div class="mt-1">
              <router-link
                to="/imgRecovery"
                class="inline-block hidden lg:block"
              >
                <div
                  class="text-sm px-2 py-1 sm:px-4 sm:py-2 border-none rounded text-white cursor-pointer mr-2 hover:text-blue-300"
                >
                  <span> Image Recovery </span>

                  <i class="fas fa-sync-alt ml-1"></i>
                </div>
              </router-link>
            </div> -->
          </div>

          <!-- <img class="h-10 inline mt-1"  />

          <h3 class="text-xl text-gray-200"></h3> -->
        </div>
        <div class="flex-grow text-right">
          <div class="inline-block">
            <router-link to="/search" class="inline-block hidden lg:block">
              <div
                class="text-sm px-2 py-1 sm:px-4 sm:py-2 border-none rounded text-white cursor-pointer mr-2 hover:text-blue-400"
              >
                <span> Search Prompt </span>

                <i class="fa fa-search ml-1"></i>
              </div>
            </router-link>
          </div>

          <div class="inline-block">
            <a href="/models" class="inline-block hidden lg:block">
              <div
                class="text-sm px-2 py-1 sm:px-4 sm:py-2 border-none rounded text-white cursor-pointer mr-2 hover:text-blue-400"
              >
                <span> Models </span>
              </div>
            </a>
          </div>

          <div class="inline-block">
            <a
              href="https://promptdue.gitbook.io/promptdue/"
              class="inline-block hidden lg:block"
              target="_blank"
            >
              <div
                class="text-sm px-2 py-1 sm:px-4 sm:py-2 border-none rounded text-white cursor-pointer mr-2 hover:text-blue-300"
              >
                <span> Docs </span>
              </div>
            </a>
          </div>

          <div class="inline-block">
            <div>
              <span v-if="checkChain">
                <!-- <img
                class="h-5 inline"
                :src="require(`@/assets/chain/${CHAIN.toLowerCase()}.png`)"
              />
              {{ CHAIN }} -->
              </span>
              <span
                class="text-sm px-2 py-1 sm:px-4 sm:py-2 border-none rounded text-white bg-gray-900 cursor-pointer mr-2"
                v-else
                v-on:click="addNetwork()"
              >
                <i class="fad fa-engine-warning mr-1" />
                Switch Network
              </span>
            </div>
          </div>

          <div
            v-if="loading"
            href="#"
            class="inline-block text-sm px-2 py-1 sm:px-4 sm:py-2 border rounded text-gray-400 border-gray-400 cursor-wait"
          >
            <i class="fad fa-spinner-third fa-spin mr-1"></i>Loading
          </div>
          <div v-else-if="token" class="inline-block">
            <router-link
              to="/funds"
              class="inline-block mt-1 text-sm px-2 py-1 sm:px-4 sm:py-2 border rounded text-yellow-50 border-none mr-2 bg-gradient-to-r from-blue-600 to-green-600"
            >
              <i class="fad fa-wallet mr-1"></i
              >{{ getUser.address.slice(0, 6) }}...{{
                getUser.address.slice(-4)
              }}
            </router-link>
            <div
              class="inline-block text-sm py-1 sm:px-2 sm:py-2 text-yellow-50 cursor-pointer"
              v-on:click="signout()"
            >
              <i class="fad fa-sign-out-alt"></i>
            </div>
          </div>
          <div v-else class="inline-block">
            <div
              class="inline-block mt-1 p-px bg-gradient-to-r from-blue-700 to-red-500 rounded-2xl"
            >
              <div
                class="inline-block text-sm px-2 py-1 sm:px-4 sm:py-2 border-none rounded-2xl text-gray-200 bg-gray-900 cursor-pointer"
                v-on:click="login()"
                v-if="!token"
              >
                Connect
              </div>
            </div>
          </div>
        </div>
      </nav>

      <div class="lg:hidden lg:block" v-if="show">
        <div class="flex-grow text-center">
          <!-- <div class="mt-3">
          <router-link to="/search" class="inline-block">
            <div
              class="text-sm px-2 py-1 sm:px-4 sm:py-2 border-none rounded text-white cursor-pointer mr-2"
            >
              <i class="fas fa-store-alt-slash mr-1"> </i>

              <span> Marketplace </span>
            </div>
          </router-link>
        </div> -->

          <div class="mt-3">
            <router-link to="/funds" class="inline-block">
              <div
                class="text-sm px-2 py-1 sm:px-4 sm:py-2 border-none rounded text-white cursor-pointer mr-2"
              >
                <i class="fas fa-user-circle mr-1"></i>

                <span> Account</span>
              </div>
            </router-link>
          </div>
          <div class="mt-3">
            <router-link to="/models" class="inline-block">
              <div
                class="text-sm px-2 py-1 sm:px-4 sm:py-2 border-none rounded text-white cursor-pointer mr-2"
              >
                <i class="fas fa-user-circle mr-1"></i>

                <span> Models</span>
              </div>
            </router-link>
          </div>
          <div class="mt-3">
            <router-link to="/sell" class="inline-block">
              <div
                class="text-sm px-2 py-1 sm:px-4 sm:py-2 border-none rounded text-white cursor-pointer mr-2"
              >
                <span> Sell </span>

                <i class="fas fa-arrow-right ml-1"></i>
              </div>
            </router-link>
          </div>
          <div class="mt-3 pb-3">
            <router-link to="/search" class="inline-block">
              <div
                class="text-sm px-2 py-1 sm:px-4 sm:py-2 border-none rounded text-white cursor-pointer mr-2"
              >
                <i class="fa fa-search mr-1"></i>

                <span> Search Prompt </span>
              </div>
            </router-link>
          </div>

          <div class="mt-3 pb-3">
            <router-link to="/genAI" class="inline-block">
              <div
                class="text-sm px-2 py-1 sm:px-4 sm:py-2 border-none rounded text-white cursor-pointer mr-2"
              >
                <i class="fas fa-pencil-alt mr-1"></i>

                <span> Text to Images </span>
              </div>
            </router-link>
          </div>

          <div class="mt-3 pb-3">
            <router-link to="/genImagetoImage" class="inline-block">
              <div
                class="text-sm px-2 py-1 sm:px-4 sm:py-2 border-none rounded text-white cursor-pointer mr-2"
              >
                <i class="fas fa-pencil-alt mr-1"></i>

                <span> Image to Image </span>
              </div>
            </router-link>
          </div>

          <div class="mt-3 pb-3">
            <router-link to="/imgRecovery" class="inline-block">
              <div
                class="text-sm px-2 py-1 sm:px-4 sm:py-2 border-none rounded text-white cursor-pointer mr-2"
              >
                <i class="fas fa-sync-alt mr-1"></i>

                <span> Image Recovery </span>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import nativeToast from "native-toast";
import Web3 from "web3";
import Web3Token from "web3-token";
import { TESTNET, CHAIN, NETWORK_VERSION } from "../config";

import { mapGetters, mapActions } from "vuex";

import user from "@/api/user";

export default {
  data() {
    return {
      CHAIN: CHAIN,
      loading: false,
      token: JSON.parse(localStorage.getItem("Token")),
      networkVersion: JSON.parse(localStorage.getItem("networkVersion")),
      checkChain: false,
      show: false,
    };
  },
  computed: mapGetters(["getToken", "getUser", "isConnected", "onlineUsers"]),
  methods: {
    ...mapActions([
      "setToken",
      "setUser",
      "fetchBalance",
      "setBalance",
      "logout",
    ]),
    async login() {
      try {
        this.loading = true;
        const web3 = new Web3(ethereum);

        await window.ethereum.request({ method: "eth_requestAccounts" });
        // getting address from which we will sign message
        this.address = (await web3.eth.getAccounts())[0];

        // generating a token with 1 day of expiration time
        this.token = await Web3Token.sign(
          (msg) => web3.eth.personal.sign(msg, this.address),
          "1d"
        );

        await user
          .login({
            token: this.token,
            ref: JSON.parse(localStorage.getItem("Ref")),
          })
          .then((response) => {
            this.loading = false;

            this.setToken(this.token);
            this.setUser(response.data.user);

            this.fetchBalance();
            this.sockets.subscribe(
              "user.balance." + this.getUser._id,
              (data) => {
                this.setBalance(data);
              }
            );

            nativeToast({
              message: "Wallet Connected",
              position: "top",
              timeout: 3000,
              type: "success",
            });
          })
          .catch((error) => {
            this.loading = false;
          });
      } catch (error) {
        this.loading = false;
        nativeToast({
          message: error.message,
          position: "top",
          timeout: 3000,
          type: "error",
        });
      }
    },
    signout() {
      this.sockets.unsubscribe("user.balance." + this.getUser._id);
      this.token = null;
      this.logout();

      setTimeout(() => {
        location.reload();
      }, 200);

      setTimeout(() => {
        this.$router.push("/");
      }, 300);
    },
    async addNetwork() {
      try {
        if (TESTNET) {
          await window.ethereum.request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId: "0x61" }],
          });
        } else {
          //Mainnet

          await window.ethereum
            .request({
              method: "wallet_switchEthereumChain",
              params: [{ chainId: "0x38" }],
            })
            .then((r) => {
              console.log(r);
            })
            .catch((e) => {
              console.log("eeee", e);
            });
        }
      } catch (switchError) {
        // This error code indicates that the chain has not been added to MetaMask.
        if (switchError.code === 4902) {
          try {
            if (TESTNET) {
              await window.ethereum.request({
                method: "wallet_addEthereumChain",
                params: [
                  {
                    chainId: "0x61",
                    chainName: "Binance Smart Chain Testnet",
                    rpcUrls: ["https://data-seed-prebsc-2-s3.binance.org:8545"],
                    nativeCurrency: {
                      name: "BINANCE COIN",
                      symbol: "TBNB",
                      decimals: 18,
                    },
                    blockExplorerUrls: ["https://testnet.bscscan.com/"],
                  },
                ],
              });
            } else {
              //Mainnet
              await window.ethereum.request({
                method: "wallet_addEthereumChain",
                params: [
                  {
                    chainId: "0x38",
                    chainName: "Binance Smart Chain Mainnet",
                    rpcUrls: ["https://bsc-dataseed.binance.org/"],
                    nativeCurrency: {
                      name: "BINANCE COIN",
                      symbol: "BNB",
                      decimals: 18,
                    },
                    blockExplorerUrls: ["https://bscscan.com/"],
                  },
                ],
              });
            }
          } catch (addError) {
            nativeToast({
              message: addError,
              position: "top",
              timeout: 3000,
              type: "error",
            });
          }
        }
        nativeToast({
          message: switchError.message,
          position: "top",
          timeout: 3000,
          type: "error",
        });
      }
      window.location.reload();
    },
    menuMobile() {
      if (this.show) {
        this.show = false;
      } else {
        this.show = true;
      }
    },
  },
  created() {
    // ethereum
    //   .request({
    //     method: "wallet_requestPermissions",
    //     params: [{ eth_accounts: {} }],
    //   })
    //   .then((permissions) => {
    //     const accountsPermission = permissions.find(
    //       (permission) => permission.parentCapability === "eth_accounts"
    //     );
    //     if (accountsPermission) {
    //       console.log("eth_accounts permission successfully requested!");
    //     }
    //   })
    //   .catch((error) => {
    //     if (error.code === 4001) {
    //       // EIP-1193 userRejectedRequest error
    //       console.log("Permissions needed to continue.");
    //     } else {
    //       console.error(error);
    //     }
    //   });

    this.show = false;

    if (window.ethereum) {
      window.ethereum.on("chainChanged", (_chainId) => {
        window.location.reload();
      });

      if (
        window.ethereum.networkVersion == NETWORK_VERSION ||
        window.ethereum.chainId == "0x38"
      ) {
        this.checkChain = true;
      }
      if (this.getUser) {
        window.ethereum.on("accountsChanged", (accounts) => {
          this.sockets.unsubscribe("user.balance." + this.getUser._id);
          this.logout();
          this.token = null;
          window.location.reload();
        });
      }
    }
  },
};
</script>
